<template>
      <iframe
          src="https://player.twitch.tv/?channel=psychoghost&parent=localhost&autoplay=true"
          allowfullscreen="false"
          frameborder="0"
          class="rounded-lg ttv"
      >
      </iframe>
<!--
    <div class="twitch-chat pt-16">
      <iframe
          frameborder="0"
          scrolling="no"
          src="https://www.twitch.tv/embed/phil_nash/chat?parent=philna.sh"
          height="100%"
          width="100%">
      </iframe>
    </div>
  -->
</template>
<script>

export default {
  name: "twitch_embed",

  components: {
  },
  data: () => ({

  }),
}
</script>

<style lang="scss" scoped>
.ttv{
  height: 100%;
  width: 92%;
  //height: 100vh;
  //width: 100vw;
  //height: 45vh;
  min-height: 460px;
  min-width: 500px;
}
</style>