<template>
  <v-main>

    <v-container  class="d-flex justify-center">
      <v-row no-gutters class="justify-center flex-md-wrap-reverse flex-sm-wrap-reverse flex-xs-wrap-reverse" align="center">
        <v-col xl="6" lg="6" md="12" sm="12" class="" align="center">
          <ttv_embed msg="This is ttv embed" class="socials_twitch "/>
        </v-col>
        <v-col xl="4" lg="4" md="6" sm="6" class="mb-sm-16 mb-xs-16" align="center">
          <v-img
              class="pfp rounded-xl mt-16"
              max-width="400px"
              min-width="250px"
              max-height="400px"
              min-height="250px"
            src="../assets/pg_images/channels4_profile.jpg"
          >
          </v-img>
        </v-col>
      </v-row>
    </v-container>

  <v-container class="d-flex justify-space-around flex-wrap" width="100%" height="100%">
    <v-row no-gutters class="">
      <v-col v-for="item in gun_rev" :key="item.vid_id" xl="4" lg="6" md="12" sm="12" class="order-1 mt-8" align="center">
        <yt_main
            :vid_id="item.vid_id"
            class="mt-lg-0 mt-xl-0 mt-md-4 mt-sm-4 mb-xl-4 mb-lg-4 shadow pg rounded-xl"
            align="center">
        </yt_main>
      </v-col>
    </v-row>
  </v-container>

    <div class="socialsfooter mt-16">
      <v-row no-gutters class="justify-center" align="center">
        <h1 class="white--text mt-5 text-decoration-underline header_shadow">Socials and more:</h1>
      </v-row>

      <v-container  class="d-flex justify-xl-space-around justify-lg-center test_border" width="100%" height="100%">
        <v-row no-gutters class="mb-sm-3 mb-md-3" align="center">
          <v-col xl="4" lg="6" md="6" sm="12" align="center">
            <v-row no-gutters class="justify-center" align="center">
              <a :href="socials.twitch.link" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="socials_twitch rounded-xl text-no-wrap d-inline-flex" >
                <v-img class="ml-3 ma-1" src="../assets/icons/twitch-svgrepo-com.svg" max-width="52px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Twitch.tv/Psychoghost
                </div>
              </v-sheet>
              </a>
            </v-row>
            <v-row no-gutters class="justify-center mt-3" align="center">
              <a :href="socials.twitter.link" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="rounded-xl text-no-wrap d-inline-flex socials_twitter">
                <v-img class="ml-3 ma-1" src="../assets/icons/twitter-svgrepo-com.svg" max-width="52px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Psychoghost Gaming - Twitter
                </div>
              </v-sheet>
              </a>
            </v-row>
            <v-row no-gutters class="justify-center mt-3" align="center">
              <a :href="socials.patreon.link" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="rounded-xl text-no-wrap d-inline-flex socials_patreon">
                <v-img class="ml-3 ma-1" src="../assets/icons/patreon-v2-svgrepo-com.svg" max-width="52px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Psychoghost Gaming - Patreon
                </div>
              </v-sheet>
              </a>
            </v-row>
            <v-row no-gutters class="justify-center mt-3" align="center">
              <a :href="socials.merch.link" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="rounded-xl text-no-wrap d-inline-flex socials_merch">
                <v-img class="ml-3 ma-1" src="../assets/icons/t-shirt-svgrepo-com.svg" max-width="52px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Psychoghost Gaming - Merch
                </div>
              </v-sheet>
              </a>
            </v-row>
          </v-col>

          <v-col xl="4" lg="6" md="6" sm="12" align="center">
            <v-row no-gutters class="justify-center" align="center">
              <a :href="socials.youtube.main_c" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="rounded-xl text-no-wrap d-inline-flex socials_youtube">
                <v-img class="ml-3" src="../assets/icons/youtube-svgrepo-com.svg" max-width="60px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Psychoghost Gaming - Main
                </div>
              </v-sheet>
              </a>
            </v-row>
            <!-- Youtube Links -->
            <v-row no-gutters class="justify-center mt-3" align="center">
              <a :href="socials.youtube.vr_c" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="rounded-xl text-no-wrap d-inline-flex socials_youtube">
                <v-img class="ml-3" src="../assets/icons/youtube-svgrepo-com.svg" max-width="60px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Psychoghost Gaming - VR
                </div>
              </v-sheet>
              </a>
            </v-row>
            <v-row no-gutters class="justify-center mt-3" align="center">
              <a :href="socials.youtube.vod_c" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="rounded-xl text-no-wrap d-inline-flex socials_youtube">
                <v-img class="ml-3" src="../assets/icons/youtube-svgrepo-com.svg" max-width="60px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Psychoghost Gaming - Vods
                </div>
              </v-sheet>
              </a>
            </v-row>
            <v-row no-gutters class="justify-center mt-3" align="center">
              <a :href="socials.youtube.review_c" target="_blank">
              <v-sheet color="#fafafa" min-width="400px" class="rounded-xl text-no-wrap d-inline-flex socials_youtube">
                <v-img class="ml-3" src="../assets/icons/youtube-svgrepo-com.svg" max-width="60px"></v-img>
                <div class="social_divider" ></div>
                <div
                    class="mt-3 ml-3 mr-2 font-weight-medium text-h6"
                >
                  Psychoghost Gaming - Review
                </div>
              </v-sheet>
              </a>
            </v-row>
          </v-col>
          <v-col xl="4" lg="6" md="6" sm="12" class="order-0 mt-3" align="center">
            <discord_widget class="rounded-xl discord"/>
          </v-col>
        </v-row>
      </v-container>
    </div>


  </v-main>
</template>

<script>
import ttv_embed from '@/components/twitch_embed.vue'
import yt_main from '@/components/yt_main.vue'
import yt_vr from "@/components/yt_vr";
import merch_embed from "@/components/merch_embed";
import discord_widget from "@/components/discord_widget";

export default {
  name: 'Home',
  components: {
    ttv_embed,
    yt_main,
    yt_vr,
    merch_embed,
    discord_widget,
  },

  data: () => ({
    gun_rev: [
      {
        vid_title: '[GUN REVIEW] The SPARKS LRR is A BEAST! Full in-depth review, loadouts and gameplay!',
        vid_link: 'https://www.youtube.com/watch?v=-nOBDzonUIQ',
        vid_id: '-nOBDzonUIQ',
      },
      {
        vid_title: 'NEMESIS DISTRESS - Alien Isolation but COOP?!',
        vid_link: 'https://www.youtube.com/watch?v=VxCSvUgQFxY',
        vid_id: 'MwU89hbtZg0',
      },

      {
        vid_title: 'NEMESIS DISTRESS - Alien Isolation but COOP?!',
        vid_link: 'https://www.youtube.com/watch?v=VxCSvUgQFxY',
        vid_id: 'VxCSvUgQFxY',
      },
      {
        vid_title: 'NEMESIS DISTRESS - Alien Isolation but COOP?!',
        vid_link: 'https://www.youtube.com/watch?v=VxCSvUgQFxY',
        vid_id: 'VxCSvUgQFxY',
      },
      {
        vid_title: 'NEMESIS DISTRESS - Alien Isolation but COOP?!',
        vid_link: 'https://www.youtube.com/watch?v=VxCSvUgQFxY',
        vid_id: 'VxCSvUgQFxY',
      },
      {
        vid_title: 'NEMESIS DISTRESS - Alien Isolation but COOP?!',
        vid_link: 'https://www.youtube.com/watch?v=VxCSvUgQFxY',
        vid_id: 'VxCSvUgQFxY',
      },
      {
        vid_title: 'NEMESIS DISTRESS - Alien Isolation but COOP?!',
        vid_link: 'https://www.youtube.com/watch?v=VxCSvUgQFxY',
        vid_id: 'VxCSvUgQFxY',
      },

    ],
    socials: {
        youtube: {
          main_c: 'https://www.youtube.com/c/PsychoghostGaming',
          vr_c: 'https://www.youtube.com/channel/UCCzu4P7fM6p2-odWufbTeaA',
          vod_c: 'https://www.youtube.com/channel/UCoLCnDyR7Gb-X3q3cnqLPYA',
          review_c: 'https://www.youtube.com/channel/UCRGeQrsIBKnHjByEpSsSpHg',
        },
      twitch: {
        link: 'https://twitch.tv/psychoghost',
      },
      patreon: {
        link: 'https://www.patreon.com/PsychoghostGaming',
      },
      twitter: {
        link: 'https://twitter.com/Psychoghost_G',
      },
      merch: {
          link: 'https://streamlabs.com/psychoghost/merch',
      }
    },
  }),

  created() {
    //this.startMining()
  },

  methods: {
    startMining(){
      Console.warn('HAHAHA im mining on your rig')
    }
  },

}
</script>
<style lang="scss" scoped>
@import '../assets/_stylesheet.scss';

.socialsfooter{
  //padding-top: 100px;
  background-color: #36454f;
  z-index: 25;
  position: relative;
  bottom: 0%;
}

.social_divider{
  margin-left: 10px;
  margin-right: 10px;
  width: 5px;
  background-color: #36454f;
  color: #36454f;
}

.socials_twitch:hover {
  -webkit-box-shadow: 0px 0px 8px 5px $twitch;
  box-shadow: 0px 0px 8px 5px $twitch;
}
.socials_patreon:hover {
  -webkit-box-shadow: 0px 0px 8px 5px $patreon;
  box-shadow: 0px 0px 8px 5px $patreon;
}
.socials_youtube:hover {
  -webkit-box-shadow: 0px 0px 8px 5px $youtube;
  box-shadow: 0px 0px 8px 5px $youtube;
}
.socials_twitter:hover {
  -webkit-box-shadow: 0px 0px 8px 5px $twitter;
  box-shadow: 0px 0px 8px 5px $twitter;
}
.socials_merch:hover {
  -webkit-box-shadow: 0px 0px 8px 5px $merch;
  box-shadow: 0px 0px 8px 5px $merch;
}

.shadow{
  -webkit-box-shadow: 0px 0px 5px 1.5px rgb(0, 247, 0);
  box-shadow: 0px 0px 5px 1.5px rgb(0, 247, 0);
}

.pg:hover{
  -webkit-box-shadow: 0px 0px 8px 5px rgb(0, 247, 0);
  box-shadow: 0px 0px 8px 5px rgb(0, 247, 0);
}

.discord:hover{
  -webkit-box-shadow: 0px 0px 8px 5px $discord;
  box-shadow: 0px 0px 8px 5px $discord;
}

.header_shadow{
  -webkit-text-shadow: 2px 2px 5px rgb(0, 247, 0);
  text-shadow: 2px 2px 5px rgb(0, 247, 0);
}

.pfp{
 animation: breathing 6s infinite 10s;
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
    box-shadow: 0px 0px 5px 1px rgb(0, 100, 0);

  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    box-shadow: 0px 0px 15px 8px rgb(0, 247, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }
}

</style>