<template>
  <div class="fog">
  <div id="foglayer_01" class="fog">
    <div class="image01"></div>
    <div class="image02"></div>
  </div>
  <div id="foglayer_02" class="fog">
    <div class="image01"></div>
    <div class="image02"></div>
  </div>
  <div id="foglayer_03" class="fog">
    <div class="image02"></div>
    <div class="image01"></div>
  </div>
  </div>
</template>

<script>
export default {
  name: "fog_background",
}
</script>

<style scoped>
.fog{
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 0;
  animation: start_fog 2.5s ;
}

@keyframes start_fog {

  0% { opacity: 0; }
  25% { opacity: 1; }
  60% { opacity: 1; }
  100% { opacity: 0; }
}


html, body {
  margin: 0;
  padding: 0;
}
body {
  background: #000;
  background: rgba(0, 0, 0, 1);
  overflow-x: hidden;
}

/* ---------- Fog ---------- */
.fogwrapper {
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
  filter: blur(1px) grayscale(0.2) saturate(1.2) sepia(0.2);
}
#foglayer_01, #foglayer_02, #foglayer_03 {
  height: 100%;
  position: absolute;
  width: 200%;
}
#foglayer_01 .image01, #foglayer_01 .image02,
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02 {
  float: left;
  height: 100%;
  width: 50%;
}
#foglayer_01 {
  -webkit-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  -moz-animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
  animation: foglayer_01_opacity 10s linear infinite, foglayer_moveme 15s linear infinite;
}
#foglayer_02, #foglayer_03 {
  -webkit-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  -moz-animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
  animation: foglayer_02_opacity 21s linear infinite, foglayer_moveme 13s linear infinite;
}

#foglayer_01 .image01, #foglayer_01 .image02 {
  background: url("../assets/Roulette/ui/fog1.png") center center/cover no-repeat transparent;
}
#foglayer_02 .image01, #foglayer_02 .image02,
#foglayer_03 .image01, #foglayer_03 .image02{
  background: url("../assets/Roulette/ui/fog2.png") center center/cover no-repeat transparent;
}

/* ---------- Keyframe Layer 1 ---------- */
@-webkit-keyframes foglayer_01_opacity {
  0% { opacity: 1; }
  22% { opacity: .7; }
  40% { opacity: .9; }
  58% { opacity: .6; }
  80% { opacity: .8; }
  100% { opacity: 1; }
}
@-moz-keyframes foglayer_01_opacity {
  0% { opacity: .8; }
  22% { opacity: 1; }
  40% { opacity: 1; }
  58% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity:1; }
}
@-o-keyframes foglayer_01_opacity {
  0% { opacity: .8; }
  22% { opacity: .9; }
  40% { opacity: .7; }
  58% { opacity: .8; }
  80% { opacity: .8; }
  100% { opacity: 1; }
}
@keyframes foglayer_01_opacity {
  0% { opacity: .8; }
  22% { opacity: 1; }
  40% { opacity: 1; }
  58% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity:1; }
}
/* ---------- Keyframe Layer 2 ---------- */
@-webkit-keyframes foglayer_02_opacity {
  0% { opacity: 1; }
  25% { opacity: .9; }
  50% { opacity: 1; }
  80% { opacity: .8; }
  100% { opacity: 1; }
}
@-moz-keyframes foglayer_02_opacity {
  0% { opacity: .9; }
  25% { opacity: .7; }
  50% { opacity: .8; }
  80% { opacity: 1; }
  100% { opacity: .7; }
}
@-o-keyframes foglayer_02_opacity {
  0% { opacity: .7; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity:1; }
}
@keyframes foglayer_02_opacity {
  0% { opacity: .9; }
  25% { opacity: 1; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: .8; }
}
/* ---------- Keyframe Layer 3 ---------- */
@-webkit-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: 1; }
  52% { opacity: 1; }
  68% { opacity: 1; }
  100% { opacity: .8; }
}
@-moz-keyframes foglayer_03_opacity {
  0% { opacity: 1 }
  27% { opacity: .9; }
  52% { opacity: .6; }
  68% { opacity: 1; }
  100% { opacity: .8; }
}
@-o-keyframes foglayer_03_opacity {
  0% { opacity: .8 }
  27% { opacity: 1; }
  52% { opacity: 1; }
  68% { opacity: 1; }
  100% { opacity: .8; }
}
@keyframes foglayer_03_opacity {
  0% { opacity: 1; }
  27% { opacity: 1; }
  52% { opacity: 1; }
  68% { opacity: 1; }
  100% { opacity: 1; }
}
/* ---------- Keyframe moveMe ---------- */
@-webkit-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-moz-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@-o-keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}
@keyframes foglayer_moveme {
  0% { left: 0; }
  100% { left: -100%; }
}

@media only screen
and (min-width: 280px)
and (max-width: 767px) {
  #foglayer_02 .image01, #foglayer_02 .image02,
  #foglayer_01 .image01, #foglayer_01 .image02,
  #foglayer_03 .image01, #foglayer_03 .image02 {
    width: 100%;
  }
}
</style>