<template>
  <iframe
      class="merch"
    src="https://streamlabs.com/psychoghost/merch"
    frameborder="0"
  >

  </iframe>
</template>

<script>
export default {
  name: "merch_embed"
}
</script>

<style scoped>
.merch{
  height: 100%;
  width: 85%;
  min-height: 400px;
  min-width: 500px;
}
</style>