import { render, staticRenderFns } from "./discord_widget.vue?vue&type=template&id=88f72046&scoped=true&"
import script from "./discord_widget.vue?vue&type=script&lang=js&"
export * from "./discord_widget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88f72046",
  null
  
)

export default component.exports