<template>
  <iframe
      class="yt_player rounded-lg"
      src="https://www.youtube.com/PsychoghostGaming"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen>

  </iframe>
</template>

<script>
export default {
  name: "yt_vr"
}
</script>

<style lang="scss" scoped>
.yt{
  height: 100%;
  width: 90%;
  min-height: 400px;
  min-width: 500px;

}
.yt_player{
  height: 100%;
  width: 85%;
  min-height: 400px;
  min-width: 500px;
}
</style>