import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import fog from '../components/fog_background'
import Roulette from "../views/Roulette";

Vue.use(VueRouter)

const routes = [
    /*
  {
    path: '/',
    name: 'Home',
    component: Home
  },
     */
  {
    path: '/',
    name: 'Roulette',
    component: Roulette
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
