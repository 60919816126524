<template>
  <v-app>
    <v-main class="backgrnd">
      <fog class="fog">
      </fog>
      <router-view class="views"/>
    </v-main>

  </v-app>
</template>

<script>
import fog from '@/components/fog_background.vue'

export default {
  name: 'App',
  components: {
    fog,
  },

  data: () => ({

  }),
};
</script>
<style lang="scss">
.fog{
  height: 100%;
  width: 100%;
}

.backgrnd{
  //background-image: url("../src/assets/background/pexels-philippe-donn-1169754.jpg");
  background-color: #000;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body::-webkit-scrollbar {
  display: none;
}

.views{
  z-index: 20;
}

</style>
