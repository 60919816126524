<template>
  <iframe
      src="https://discord.com/widget?id=896782953143738428&theme=dark"
      width="400"
      height="300"
      allowtransparency="true"
      frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
</template>

<script>
export default {
  name: "discord_widget",
}
</script>

<style scoped>

</style>